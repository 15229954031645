<template>
  <v-card class="mx-auto">
    <v-card-title>
      <span class="font-weight-light text-capitalize text-truncate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="yellow darken-3"
              text-color="white"
              small
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small left> mdi-star </v-icon>
              {{ item.purchasedForPoints }}
            </v-chip>
          </template>
          <span>{{ $t("tooltip.points") }}</span> </v-tooltip
        >{{ item.reward?.name }}</span
      >
    </v-card-title>
    <v-img
      class="white--text align-end"
      contain
      :height="imageHeight"
      :src="item.reward?.pictureUrl"
      @error="item.reward.pictureUrl = require('@/assets/picture-loading-failed-1.png')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-divider> </v-divider>
    <v-card-subtitle class="px-4 py-2">
      <span class="font-weight-bold">
        <v-icon left> mdi-account </v-icon>{{ item.buyerUsername }}</span
      >
    </v-card-subtitle>
    <v-divider v-if="!isAdmin()"> </v-divider>
    <v-card-actions v-if="!isAdmin()">
      <span class="caption grey--text font-weight-bold">{{ formatDate(item.purchaseDate) }}</span>
      <v-spacer></v-spacer>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 0">
        <v-icon left color="error">mdi-timer-sand</v-icon>
        <span>{{ $t("filters.purchaseFilter.WaitingForApproval") }}</span>
      </v-btn>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 1">
        <v-icon left color="info">mdi-check-circle-outline</v-icon>
        <span>{{ $t("filters.purchaseFilter.Approved") }}</span>
      </v-btn>
      <v-btn text depressed plain :ripple="false" v-if="item.state == 2">
        <v-icon left color="success">mdi-truck</v-icon>
        <span>{{ $t("filters.purchaseFilter.Delivered") }}</span>
      </v-btn>
    </v-card-actions>
    <v-divider v-if="showActions && isAdmin()"> </v-divider>
    <v-card-actions v-if="showActions && isAdmin()">
      <span class="caption grey--text font-weight-bold">{{ formatDate(item.purchaseDate) }}</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="item.state == 2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" small text depressed plain :ripple="false">
            <v-icon left color="success">mdi-truck</v-icon>
            <span>{{ $t("filters.purchaseFilter.Delivered") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("filters.purchaseFilter.Delivered") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.state == 3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" small text depressed plain :ripple="false">
            <v-icon left color="success">mdi-cancel</v-icon>
            <span>{{ $t("filters.purchaseFilter.Canceled") }}</span>
          </v-btn>
        </template>
        <span>{{ $t("filters.purchaseFilter.Canceled") }}</span>
      </v-tooltip>
      <div class="d-flex">
        <v-tooltip bottom v-if="item.state == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              plain
              small
              color="error"
              @click="cancelPurchase(item.id)"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ $t("app.cancel") }}
            </v-btn>
          </template>
          <span>{{ $t("tooltip.cancel") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.state == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              plain
              small
              color="info"
              @click="deliverPurchase(item.id)"
            >
              <v-icon left>mdi-truck</v-icon>
              {{ $t("app.deliver") }}
            </v-btn>
          </template>
          <span>{{ $t("tooltip.deliver") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.state == 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              depressed
              small
              color="success"
              @click="approvePurchase(item.id)"
            >
              <v-icon left>mdi-check-circle-outline</v-icon>
              {{ $t("app.approve") }}
            </v-btn>
          </template>
          <span>{{ $t("app.approve") }}</span>
        </v-tooltip>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    imageHeight: {
      type: String,
      required: false,
      default: "200px"
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    async approvePurchase(id) {
      await this.$store.dispatch(`${this.namespace}/approve`, id);
      Event.$emit("reload-data");
    },
    async cancelPurchase(id) {
      await this.$store.dispatch(`${this.namespace}/cancel`, id);
      Event.$emit("reload-data");
    },
    async deliverPurchase(id) {
      await this.$store.dispatch(`${this.namespace}/deliver`, id);
      Event.$emit("reload-data");
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
</style>
